import * as Repository from "../repositories/news-list-repository.js";
import * as BaseHandler from "./v3-site-calendar-handler.js";

export function loadInfo() {
    const calendar = document.querySelector(".events-calendar");
    if (!calendar) {
        return null;
    }

    const currentDate = BaseHandler.getCurrentDate(calendar);
    if (!currentDate) {
        return null;
    }

    const params = BaseHandler.getDateMonthParams(currentDate);

    Repository.getDates(params).then((response) => {
        if (response.data && response.data.list) {
            buildCalendarLinks(calendar, response.data.list);
        }
    });
}

function buildCalendarLinks(calendar, dates) {
    let days = BaseHandler.prepareCalendarDays(dates);

    calendar.querySelectorAll(".cell p.numb").forEach((element) => {
        if (element.dataset.url) {
            return null;
        }
        const calendarDayNum = parseInt(element.innerText);
        if (calendarDayNum && !isNaN(calendarDayNum) && days[calendarDayNum]) {
            element.classList.add("event");
            element.classList.remove("notstarted");
            element.dataset.url =
                "/site/day-events?date=" + days[calendarDayNum];
        }
    });
}
