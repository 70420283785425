import * as Repository from "../repositories/news-list-repository.js";

export function loadInfo() {
    const calendar = document.querySelector(".calendar-widget");
    if (!calendar) {
        return null;
    }

    const currentDate = getCurrentDate(calendar);
    if (!currentDate) {
        return null;
    }

    const params = getDateMonthParams(currentDate);

    Repository.getDates(params).then((response) => {
        if (response.data && response.data.list) {
            buildCalendarLinks(calendar, response.data.list);
        }
    });
}

/* HELPERS */

export function getCurrentDate(calendar) {
    const thisMonthLink = calendar.querySelector(".block-header a.month");

    const params = new Proxy(
        new URLSearchParams(thisMonthLink.href.split("?").pop()),
        {
            get: (searchParams, prop) => searchParams.get(prop),
        }
    );

    if (!params.year || !params.month) {
        return null;
    }

    return {
        year: params.year,
        month: params.month,
    };
}

export function getDateMonthParams(currentDate) {
    const dateFrom = new Date(
        currentDate.year + "-" + currentDate.month + "-01"
    );
    const dateTo = new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 1, 0);
    const params = {};
    params.date_from =
        dateFrom.getFullYear() +
        "-" +
        ("0" + (dateFrom.getMonth() + 1)).slice(-2) +
        "-01";
    params.date_to =
        dateTo.getFullYear() +
        "-" +
        ("0" + (dateTo.getMonth() + 1)).slice(-2) +
        "-" +
        dateTo.getDate();

    return params;
}

export function buildCalendarLinks(calendar, dates) {
    let days = prepareCalendarDays(dates);

    calendar.querySelectorAll(".cell p.numb").forEach((element) => {
        if (element.querySelector("a")) {
            return null;
        }
        const calendarDayNum = parseInt(element.innerText);
        if (calendarDayNum && !isNaN(calendarDayNum) && days[calendarDayNum]) {
            element.innerHTML =
                '<a href="/site/day-events?date=' +
                days[calendarDayNum] +
                '" class="event">' +
                element.innerText +
                "</a>";
        }
    });
}

export function getDateFromString(dateString) {
    var pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
    return new Date(dateString.replace(pattern, "$3-$2-$1"));
}

export function prepareCalendarDays(dates) {
    let days = {};
    dates.forEach((dateString) => {
        const day = parseInt(dateString.split("-")[0]);
        const date = getDateFromString(dateString);
        days[day] =
            date.getFullYear() +
            "-" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + date.getDate()).slice(-2);
    });

    return days;
}
