import * as Store from "./store.js";
import * as Repository from "./repositories/repository.js";
import * as SiteCalendarHandler from "./handlers/site-calendar-handler.js";
import * as V3SiteCalendarHandler from "./handlers/v3-site-calendar-handler.js";
import * as V5SiteCalendarHandler from "./handlers/v5-site-calendar-handler.js";

export default class CalendarApplicationLoader {
    loadApplication(config) {
        this.configure(config);

        this.loadInfo();

        window.addApricodeHandler("onCalendarMonthLoad", () => {
            this.loadInfo();
        });
    }

    configure(config) {
        if (!config.store) {
            config.store = {};
        }
        if (config.debug) {
            config.store.debug = config.debug;
        }

        // мерж с дефолтным конфигом
        config = {
            ...(Store.getStore("config") || {}),
            ...config,
        };

        Repository.setConfig(config.repository);
        Store.setConfig(config.store);
        Store.setStore("config", config);
    }

    loadInfo() {
        switch (Store.getStore("config.theme.name")) {
            case "v5":
                V5SiteCalendarHandler.loadInfo();
                break;
            case "v3":
            case "v4":
            case "v6":
                V3SiteCalendarHandler.loadInfo();
                break;
            default:
                SiteCalendarHandler.loadInfo();
                break;
        }
    }
}
