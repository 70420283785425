import * as Repository from "../repositories/news-list-repository.js";
import * as BaseHandler from "./site-calendar-handler.js";

export function loadInfo() {
    const calendar = document.querySelector(".events-calendar");
    if (!calendar) {
        return null;
    }

    const currentDate = getCurrentDate(calendar);
    if (!currentDate) {
        return null;
    }

    const params = BaseHandler.getDateMonthParams(currentDate);

    Repository.getDates(params).then((response) => {
        if (response.data && response.data.list) {
            buildCalendarLinks(calendar, response.data.list);
        }
    });
}

/* HELPERS */

export function getCurrentDate(calendar) {
    const thisMonthLink = calendar.querySelector(".prev_month");

    const params = new Proxy(
        new URLSearchParams(thisMonthLink.dataset["url"].split("?").pop()),
        {
            get: (searchParams, prop) => searchParams.get(prop),
        }
    );

    if (!params.year || !params.month) {
        return null;
    }

    let date = {
        year: parseInt(params.year),
        month: parseInt(params.month) + 1,
    };

    if (date.month > 12) {
        date.year++;
        date.month = 1;
    }

    date.month = ("0" + date.month).slice(-2);

    return date;
}

export function buildCalendarLinks(calendar, dates) {
    let days = BaseHandler.prepareCalendarDays(dates);

    calendar.querySelectorAll(".cell").forEach((element) => {
        if (element.dataset.url) {
            return null;
        }
        const calendarDayNum = parseInt(element.innerText);
        if (calendarDayNum && !isNaN(calendarDayNum) && days[calendarDayNum]) {
            element.classList.add("event");
            element.classList.remove("notstarted");
            element.dataset.url =
                "/site/day-events?date=" + days[calendarDayNum];
            let p = element.querySelector("p.numb");
            p.innerHTML = "<span>" + p.innerText + "</span>";
        }
    });
}

// для потомков
export function getDateMonthParams(currentDate) {
    return BaseHandler.getDateMonthParams(currentDate);
}
export function prepareCalendarDays(dates) {
    return BaseHandler.prepareCalendarDays(dates);
}
