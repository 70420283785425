export let config = {
    baseUrl: "",
    queryUrl: "/query",
    authLayoutUrl: "/auth",
};

export function setConfig(newConfig) {
    if (newConfig) {
        for (let name in newConfig) {
            config[name] = newConfig[name];
        }
    }
}

export function request(method, url, body, options) {
    if (url.indexOf(config.baseUrl) <= -1) {
        url = config.baseUrl + url;
    }

    options = options || {};
    options.method = method;
    options.body = body;

    return fetch(url, options).then((response) => {
        if (response.status >= 200 && response.status < 300) {
            // все хорошо
            return response.json();
        }

        throw new Error("Request fail");
    });
}

export function queryRequest(query) {
    return request("post", config.baseUrl + config.queryUrl, {
        query: JSON.stringify(query),
    });
}
